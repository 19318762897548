<template>
  <Modal :value="visible"
         title="位置详情"
         width="540"
         footer-hide
         @on-visible-change="onChangeVisible">
    <div class="modal-cont">
      <div class="mapDiv"
           ref="positionDetailmapDiv">
      </div>
    </div>
  </Modal>
</template>

<script>
import tMap from '@/utils/tMap'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    address: String,
    lng: [Number, String],
    lat: [Number, String]
  },
  data () {
    return {
      map: null,  // 天地图实例
      zoom: 14, // 初始地图缩放等级
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
  },
  mounted () {
    // this.init()
  },
  methods: {
    init () {
      if (!this.map) return this.createMap()
      this.createMarker()
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.map = new T.Map(this.$refs.positionDetailmapDiv)
        this.createMarker()
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    // 生成标记点
    async createMarker () {
      //创建图片对象
      let icon = new T.Icon({
        iconUrl: require('../../../../assets/main/guidepost.png'),
        iconSize: new T.Point(24, 32),
        iconAnchor: new T.Point(10, 30)
      })
      let lnglat = new T.LngLat(this.lng, this.lat)
      let marker = new T.Marker(lnglat, { icon: icon })
      let address = this.address
      if (!address) {
        address = await this.getAddress(lnglat)
      }
      let content = `<div>${this.address}</div>`
      let label = new T.Label({ text: content, position: lnglat, offset: new T.Point(0, -40) })
      this.map.centerAndZoom(lnglat, this.zoom)
      this.map.addOverLay(marker)
      this.map.addOverLay(label)
    },
    // 逆地理编码
    async getAddress (lnglat) {
      const geocode = new T.Geocoder()
      return new Promise((resolve) => {
        geocode.getLocation(lnglat, (e) => {
          if (e.getStatus() === 0) {
            const addressComponent = e.getAddressComponent()
            resolve(addressComponent.address)
          } else {
            resolve(lnglat.lng + ',' + lnglat.lat)
          }
        })
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.map.clearOverLays()
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mapDiv {
  width: 100%;
  height: 400px;
}
</style>
